<template>
  <DialogBase
    :dialogWidth="900"
    :dialogModel="dialogModel"
    @closeDialog="(val) => closeDialog(val)"
    scrollable
  >
    <template v-slot:title>
      {{ selectedModel ? `Update` : `Create` }} Blog
    </template>

    <v-form
      id="form"
      style="margin-top: 20px; margin-bottom: 40px"
      @submit.prevent
      v-model="isValidForm"
      ref="from"
    >
      <FormMaker :schema="form_structure" :model="form_data"></FormMaker>
    </v-form>

    <template v-slot:footer>
      <v-btn color="accent" outlined block @click="submit()">
        {{ selectedModel ? `Update` : `Create` }}
      </v-btn>
    </template>
  </DialogBase>
</template>

<script>
import { required, minLen } from "@/plugins/validationRules.js";
import FormMaker from "@/components/FormMaker";
import DialogBase from "@/components/dialogs/Base";
export default {
  components: { DialogBase, FormMaker },
  props: {
    dialogModel: { required: true, type: Boolean },
    selectedModel: { required: true, default: null },
  },
  data() {
    return {
      isValidForm: false,
      form_data: {},
      default_form_data: {
        url: null,
        title: null,
        published_at: null,
        author_data: {
          name: null,
          about: null,
        },
        meta_data: {
          title: null,
          description: null,
          tags: null,
          keywords: null,
          thumbnail_url: null,
          thumbnail_caption: null,
          short_description: null,
          long_description: null,
        },
        contentType: "html viewer",
        content: null,
      },
    };
  },
  mounted() {
    if (this.selectedModel) {
      this.form_data = {
        ...this.default_form_data,
        ...this.selectedModel,
        contentType: "html viewer",
      };
    } else {
      this.form_data = { ...this.default_form_data };
    }
  },
  watch: {
    selectedModel(newVal, oldVal) {
      if (newVal) this.form_data = Object.assign({}, this.selectedModel);
    },
  },
  computed: {
    agentsList() {
      return this.$store.getters["assignableAgentListsMapped"];
    },
    form_structure() {
      let form = {
        author_data: {
          name: {
            type: "text",
            name: "name",
            label: `Author Name`,
            ...this.$helpers.formFieldsBasicLayout,
            rules: [required()],
          },
          about: {
            type: "textarea",
            name: "about",
            label: `About Author`,
            ...this.$helpers.formFieldsBasicLayout,
            rules: [required()],
          },
        },
        title: {
          type: "text",
          name: "Title",
          label: `Blog Title`,
          ...this.$helpers.formFieldsBasicLayout,
          rules: [required()],
        },
        url: {
          type: "text",
          name: "url",
          label: `Url`,
          ...this.$helpers.formFieldsBasicLayout,
          rules: [required()],
        },
        published_at: {
          type: "text",
          ext: "date",
          name: "published_at",
          label: `Article Published At`,
          ...this.$helpers.formFieldsBasicLayout,
          rules: [required()],
        },
        meta_data: {
          thumbnail_url: {
            type: "text",
            name: "thumbnail_url",
            label: `Thumbnail url`,
            ...this.$helpers.formFieldsBasicLayout,
            rules: [required()],
          },
          thumbnail_caption: {
            type: "text",
            name: "thumbnail_caption",
            label: `Thumbnail caption`,
            ...this.$helpers.formFieldsBasicLayout,
            rules: [required()],
          },
          title: {
            type: "text",
            name: "Meta Title",
            label: `Meta Title`,
            ...this.$helpers.formFieldsBasicLayout,
            rules: [required()],
          },
          description: {
            type: "textarea",
            name: "meta_description",
            label: `Meta Description`,
            ...this.$helpers.formFieldsBasicLayout,
            rules: [required()],
          },
          short_description: {
            type: "textarea",
            name: "short_description",
            label: `Short Description`,
            ...this.$helpers.formFieldsBasicLayout,
            rules: [required()],
          },
          long_description: {
            type: "textarea",
            name: "long_description",
            label: `long Description`,
            ...this.$helpers.formFieldsBasicLayout,
            rules: [required()],
          },
          tags: {
            type: "combobox",
            name: "tags",
            label: "Search Tags",
            ...this.$helpers.formFieldsBasicLayout,
            items: [],
            multiple: true,
            returnObject: false,
            chips: true,
            clearChips: true,
            rules: [required()],
          },
          keywords: {
            type: "combobox",
            name: "meta_keywords",
            label: "Meta Keywords",
            ...this.$helpers.formFieldsBasicLayout,
            items: [],
            multiple: true,
            returnObject: false,
            chips: true,
            clearChips: true,
            rules: [required()],
          },
        },
        contentType: {
          type: "btn-toggle",
          name: "contentType",
          label: "Content Type",
          ...this.$helpers.formFieldsBasicLayout,
          options: ["html viewer", "html editor"],
          mandatory: true,
        },
        content: {
          type:
            this.form_data?.contentType == "html editor"
              ? "text-editor"
              : "textarea",
          name: "content",
          label: "content",
          contentType: "html",
          ...this.$helpers.formFieldsBasicLayout,
          rows: 6,
          rules: [],
        },
      };
      return form;
    },
  },
  methods: {
    closeDialog(val = false) {
      this.$emit("closeDialog", val);
    },
    refresh(val = false) {
      this.$emit("refresh");
    },
    async submit() {
      this.$refs.from.validate();
      if (!this.isValidForm) return;

      let data = {
        ...this.form_data,
      };

      if (this.selectedModel)
        return await this.post(
          `${this.baseUrl}/services/quotes-portal/blog/update-blog`,
          data,
          true
        ).then((data) => {
          if (data == "undefined") return;
          this.showMessageFromResponseDta(data);

          if (data.code == 200) {
            this.refresh();
            this.closeDialog();
          }
        });

      return await this.post(
        `${this.baseUrl}/services/quotes-portal/blog/create-blog`,
        data,
        true
      ).then((data) => {
        if (data == "undefined") return;
        this.showMessageFromResponseDta(data);

        if (data.code == 200) {
          this.refresh();
          this.closeDialog();
        }
      });
    },
  },
};
</script>
